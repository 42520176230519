import React, { useState } from 'react';
import SideBar from '../SideBare/index'
import Navbar from '../Navbar/index'
import { motion, AnimatePresence } from 'framer-motion';


const MainLayout = ({ children }) => {
    const [open, setOpen] = useState(true)

    return (
        <div className="flex  h-screen overflow-y-auto">
            <SideBar open={open} setOpen={setOpen} />
            <div className={`flex-1 overflow-x-hidden bg-neutral-100 dark:bg-neutral-900 transition-all duration-300 flex flex-col`}>
                <Navbar open={open} setOpen={setOpen} />
                <main className={`flex-1 max-w-full  mt-[5.5rem] ${open ? 'lg:ml-[15.9%] lg:w-[83.2%]' : 'w-full  lg:pl-[6rem]'} `}>
                    {children}
                </main>
            </div>
        </div>
    );
};

export default MainLayout;
