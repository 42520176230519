import Routers from './Routers/Routers';
import { ThemeProvider as CustomThemeProvider } from './utils/context/ThemeContext';
import { AuthProvider } from './utils/context/AuthContext';
import { UserProvider } from './utils/context/CurrentUser';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div className='bg-neutral-100 dark:bg-neutral-900' style={{ textAlign: 'center', width: '100vw' }}>
          <CustomThemeProvider>
            <AuthProvider>
              <UserProvider>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <Routers />
              </UserProvider>
            </AuthProvider>
          </CustomThemeProvider>
    </div>
  );
}

export default App;
