// components/RichTextEditor.jsx
import React, { useEffect, useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const RichTextEditor = ({ value, onChange, placeholder }) => {
    const editor = useRef(null);
    const [content, setContent] = useState(value); // Local state for editor content

    const config = useMemo(
        () => ({
            readonly: false, // Enable editing
            placeholder: placeholder || 'Start typing...', // Use provided placeholder or default
            toolbar: [
                'bold', 'italic', 'underline', 'strikethrough', '|',
                'ul', 'ol', '|',
                'image', 'file', 'link', '|',
                'align', 'undo', 'redo', '|',
                'clean', 'paragraph', 'font', 'fontsize', '|',
                'emoticons', 'charmap'
            ],
        }),
        [placeholder]
    );

    useEffect(() => {
        setContent(value); // Update local state when value prop changes
    }, [value]);

    const handleBlur = (newContent) => {
        setContent(newContent);
        onChange(newContent); // Pass the updated content to the parent on blur
    };

    return (
        <div className="rich-text-editor w-full">
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // Tab index of textarea
                onBlur={handleBlur} // Update content on blur
                onChange={(newContent) => {}} // No action on change to optimize performance
                className='h-72 rounded-xl border-neutral-200 dark:border-neutral-700'
            />
        </div>
    );
};

export default RichTextEditor;
