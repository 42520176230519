import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import DropIn from "braintree-web-drop-in-react";
import card from '../../assets/images/card.png'
import paypal from '../../assets/images/paypal.png'

function PayementModal({ open, setOpen, setDone, done }) {
    const { t } = useTranslation();
    const [paymentMethod, setPaymentMethod] = useState(null)

    const [clientToken, setClientToken] = useState(null);
    const [instance, setInstance] = useState(null);
    const [promoCodeValues, setPromoCodeValues] = useState({ discount_value: null, discount_type: null })
    const [refrech, setRefrech] = useState(false);

    useEffect(() => {
        fetchClientToken();
        checkUserPromoCode()
    }, [refrech, open]);

    const fetchClientToken = async () => {
        const response = await axiosInstance.get("/payment/get-client-token");
        const { clientToken } = response.data;
        setClientToken(clientToken);

    };

    const checkUserPromoCode = async () => {
        try {
            const response = await axiosInstance.get("/payment/check-user-promo-code-validity");
            const responseData = response.data;
            if (responseData.success) {
                setPromoCodeValues({
                    discount_value: responseData.discount_value,
                    discount_type: responseData.discount_type
                });
            } else {
                setPromoCodeValues({ discount_value: 0, discount_type: '' });
                console.log('Promo code is invalid: ', responseData.message);
            }
        } catch (error) {
            console.error("An error occurred while checking the promo code:", error);
            setPromoCodeValues({ discount_value: 0, discount_type: '' });
        }
    };


    const { checkAccess } = useAccessChecker();

    const [loading, setLoding] = useState(false)

    const Amounts = [10, 25, 50, 75, 100, 150, 300, 450]

    const [AmountToPay, seTAmountToPay] = useState(0)
    const [OtherAmount, setOtherAmount] = useState(false)
    const [chosenPack, setChosenPack] = useState(null)
    const [packDetails, setPackDetails] = useState(null)



    const TogglePackDetails = (id) => {
        if (id == packDetails) {
            setPackDetails(null)
        } else {
            setPackDetails(id)
        }


    }
    const [tabNumber, setTabNumber] = useState(1)
    const [Pack, setPack] = useState([])

    useEffect(() => {
        seTAmountToPay(0)
        setChosenPack(null)
        setPackDetails(null)
        setOtherAmount(false)
        setPaymentMethod(null)
    }, [tabNumber])

    const ToggleChosenPack = (item) => {
        if (item.id == chosenPack) {
            setChosenPack(null)
            seTAmountToPay(0)
        } else {
            setChosenPack(item.id)
            seTAmountToPay(item.price)

        }


    }


    useEffect(() => {
        getPack()
    }, [tabNumber])


    const getPack = async (page = 1) => {
        try {
            setLoding(true)
            const response = await axiosInstance.post(`/pack/search`);
            const responseData = response.data

            if (responseData) {
                setPack(responseData.data)

            }

            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const discountAmount = () => {

        let amountDiscount = 0;

        if (promoCodeValues?.discount_type) {
            if (promoCodeValues?.discount_type === 'fixed') {
                amountDiscount = promoCodeValues?.discount_value;  // Fixed discount
            } else if (promoCodeValues?.discount_type === 'percentage') {
                // Percentage discount applied to AmountToPay
                amountDiscount = (AmountToPay * promoCodeValues?.discount_value) / 100;
            }
        }

        return amountDiscount ? `${amountDiscount}$` : `0$`; // Return 0$ if no valid discount
    };

    const finalAmount = () => {
        // Calculate the final amount after applying the discount
        const amountDiscount = promoCodeValues?.discount_type === 'fixed'
            ? promoCodeValues.discount_value
            : (AmountToPay * promoCodeValues?.discount_value) / 100;

        const totalAmountAfterDiscount = AmountToPay - amountDiscount;
        return totalAmountAfterDiscount.toFixed(2); // Ensure the result has 2 decimal places
    };


    const handlePayment = async (e) => {
        e.preventDefault();

        try {
            setLoding(true)

            const { nonce } = await instance.requestPaymentMethod();

            const response = await axiosInstance.post("/payment/process-payment", {
                nonce,
                amount: finalAmount(),
                AmountToPay : AmountToPay ,
                pack: chosenPack || null
            });

            if (response.data.success) {
                toast.success("Payment Successful!");
                setDone(!done)
                setOpen(false)
                setLoding(false)

            } else {
                toast.error("Payment Failed: " + response.data.message);
                setLoding(false)

            }
            setRefrech(!refrech)
        } catch (error) {
            setLoding(false)

        }
    };


    return (
        <AnimatePresence>

            {open &&
                <div
                    className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                    <div className="absolute bg-black opacity-80 inset-0 z-0" />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                        className="w-full flex justify-center max-h-full overflow-y-auto  items-center  2xl:w-[35%] xl:w-[45%] lg:w-[50%] md:w-[60%]  w-full flex-col   py-4 px-6 relative mx-auto mx-2   ">


                        <div className='overflow-y-auto relative h-full rounded-t-xl w-full flex justify-center py-5  md:px-7 px-4 items-center flex-col gap-4 border-b border-neutral-200 dark:border-neutral-700  bg-white dark:bg-neutral-800'>
                            <a onClick={() => setOpen(false)} className='flex absolute cursor-pointer top-2 right-2 justify-center hover:scale-110 transition-all duration-400 items-center p-1  dark:text-neutral-400'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>

                            <div className='grid place-items-start w-full gap-3'>

                                <h1 className='text-neutral-700 dark:text-neutral-400 text-md font-semibold'>Recharge Account : </h1>
                                <div className="tabs my-3 w-full">
                                    <div className="flex w-full ">
                                        <ul className="flex w-fit gap-1 bg-neutral-100 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200 rounded-2xl transition-all duration-300 p-2  overflow-hidden">
                                            <li className={`flex ${tabNumber === 1 ? 'bg-white dark:bg-neutral-800' : ''} cursor-pointer w-fit rounded-lg justify-center items-center hover:bg-white dark:hover:bg-neutral-800 `}>
                                                <a onClick={() => setTabNumber(1)} className="inline-block py-2 px-4  font-medium text-sm   whitespace-nowrap" > Choose Amount </a>
                                            </li>
                                            <li className={`flex ${tabNumber === 2 ? 'bg-white dark:bg-neutral-800' : ''} cursor-pointer w-fit rounded-lg justify-center items-center hover:bg-white dark:hover:bg-neutral-800 `}>
                                                <a onClick={() => setTabNumber(2)} className="inline-block py-2 px-4  font-medium  text-sm whitespace-nowrap" > Choose Package </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="mt-5 flex w-full">

                                        {
                                            tabNumber === 1 &&
                                            <div className="grid gap-3 w-full" >
                                                <div className='flex  md:flex-row flex-wrap justify-center items-center gap-2'>

                                                    {
                                                        Amounts.map(item => {
                                                            return (
                                                                <a onClick={() => { seTAmountToPay(item); setOtherAmount(false) }} className={`flex transition-all rounded-md duration-300 cursor-pointer ${item == AmountToPay ? 'border-green-600 dark:border-green-600 bg-green-600/10 dark:bg-green-600/10 text-green-600 dark:text-green-600' : 'border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200'} justify-center items-center px-3 py-1.5 border  text-[16px] font-[600]`}>
                                                                    {item}
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                    <a onClick={() => { setOtherAmount(true); seTAmountToPay(null) }} className={`flex transition-all rounded-md duration-300 cursor-pointer ${OtherAmount ? 'border-green-600 dark:border-green-600 bg-green-600/10 dark:bg-green-600/10 text-green-600 dark:text-green-600' : 'border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200'} justify-center items-center px-3 py-1.5 border  text-[16px] font-[600]`}>
                                                        Other
                                                    </a>
                                                </div>

                                                {
                                                    OtherAmount &&
                                                    <div className="relative w-full">
                                                        <input onChange={(e) => seTAmountToPay(e.target.value)} defaultValue={AmountToPay} type='number' id="hs-floating-input-email" className="peer p-4 block w-full border bg-neutral-50  border-neutral-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600
                focus:pt-6
                focus:pb-2
                [&:not(:placeholder-shown)]:pt-6
                [&:not(:placeholder-shown)]:pb-2
                autofill:pt-6
                autofill:pb-2" placeholder />
                                                        <label htmlFor="hs-floating-input-email" className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                peer-focus:scale-90
                peer-focus:translate-x-0.5
                peer-focus:-translate-y-1.5
                peer-focus:text-gray-500 dark:peer-focus:text-gray-500
                peer-[:not(:placeholder-shown)]:scale-90
                peer-[:not(:placeholder-shown)]:translate-x-0.5
                peer-[:not(:placeholder-shown)]:-translate-y-1.5
                peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-gray-500 dark:text-gray-500">Amount</label>
                                                    </div>
                                                }


                                            </div>
                                        }
                                        {
                                            tabNumber === 2 &&
                                            <div className="flex flex-col justify-center items-center gap-2  w-full " >
                                                {
                                                    Pack.length > 0 ?
                                                        Pack.map((item) => {
                                                            if (item.is_active) {
                                                                return (


                                                                    <div key={item.id} className={`flex flex-col text-[16px] relative font-[600] shadow rounded-md items-center w-full py-3 px-4 gap-3 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200`}>
                                                                        {item.is_popular ?
                                                                            <div className='absolute h-6 text-[10px] font-[500] border rounded z-50  border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 px-2 flex justify-center items-center -top-3 left-2'>Popular</div>
                                                                            :
                                                                            null

                                                                        }
                                                                        <div className='flex justify-between items-center py-1  w-full'>
                                                                            <div className='flex justify-start items-center gap-2'>
                                                                                <a onClick={() => ToggleChosenPack(item)} className={`flex ${item.id == chosenPack ? 'bg-green-600/10 text-green-600 ' : 'bg-black/10 dark:bg-white/10'} justify-center items-center  p-1  rounded-full`}>
                                                                                    {item.id !== chosenPack ?
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>

                                                                                        :
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-check"><path d="M20 6 9 17l-5-5" /></svg>

                                                                                    }
                                                                                </a>



                                                                                <p> {item.name}  /  <span className='text-md'>{item.price}$</span> </p>

                                                                            </div>

                                                                            <a onClick={() => TogglePackDetails(item.id)} className='flex justify-center items-center p-1 bg-black/10 dark:bg-white/10 rounded-full'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className={`lucide lucide-chevron-down ${item.id === packDetails ? 'rotate-180' : ''}`}><path d="m6 9 6 6 6-6" /></svg>
                                                                            </a>
                                                                        </div>

                                                                        {item.id === packDetails &&
                                                                            <div className='flex flex-col justify-center p-2 items-start gap-2  w-full'>
                                                                                {
                                                                                    item.features.map(item => {
                                                                                        return (
                                                                                            <p className='text-[14px] font-[500] bg-black/10 dark:bg-white/10 rounded-md px-4 py-2 w-fit'>{item}</p>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        }


                                                                    </div>
                                                                )
                                                            }

                                                        })
                                                        :
                                                        <p className="text-gray-500 "> There is no <em className="font-semibold text-gray-800 ">Packages</em> available now. </p>

                                                }

                                            </div>

                                        }

                                    </div>
                                </div>

                                <h1 className='text-neutral-700 dark:text-neutral-400 text-md w-full text-start font-semibold'>Payment method : </h1>



                                <div style={{ width: '100%' }}>
                                    <DropIn
                                        options={{
                                            authorization: clientToken,
                                            paypal: {
                                                flow: 'vault',
                                            },
                                        }}
                                        onInstance={(dropinInstance) => setInstance(dropinInstance)}
                                    />
                                </div>







                            </div>






                        </div>
                        <div className='overflow-y-auto rounded-b-xl w-full flex py-5 md:px-7 px-4 justify-center items-center flex-col gap-4 shadow-lg  bg-white dark:bg-neutral-800'>

                            <div className='flex justify-between w-full  items-center '>
                                <div className='flex flex-col justify-cenetr items-start gap-4 '>
                                    <p className="md:text-[16px] text-[14px] font-[500] text-neutral-500 dark:text-neutral-300">Recharge amount:</p>
                                    <p className="md:text-[16px] text-[14px] font-[500] text-neutral-500 dark:text-neutral-300">Discount :</p>
                                    <p className="md:text-[18px] text-[16px] font-[700] text-neutral-800 dark:text-white">Total for payment:</p>

                                </div>

                                <div className='flex flex-col justify-cenetr items-end gap-4 '>
                                    <p className="md:text-[16px] text-[14px] font-[500] text-neutral-500 dark:text-neutral-300">
                                        {AmountToPay}$  {/* Original amount */}
                                    </p>
                                    <p className="md:text-[16px] text-[14px] font-[500] text-neutral-500 dark:text-neutral-300">
                                        {discountAmount()}  {/* Discount amount */}
                                    </p>
                                    <p className="md:text-[18px] text-[16px] font-[700] text-neutral-800 dark:text-white">
                                        {finalAmount()}$  {/* Final amount after discount */}
                                    </p>
                                </div>

                            </div>
                            <div className='flex justif-center items-center w-full gap-2'>
                                <button onClick={(e) => handlePayment(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-[15px] tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0  gap-x-2  dark:bg-neutral-100">
                                    {
                                        !loading ?
                                            (
                                                <>

                                                    <span>Pay {finalAmount()} $</span>
                                                </>
                                            )
                                            :

                                            (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                <span className="sr-only">Loading...</span>
                                            </div>)

                                    }

                                </button>

                            </div>




                        </div>
                    </motion.div>
                </div>
            }
        </AnimatePresence >

    )
}

export default PayementModal