import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser } from '../api/users';
import { useAuth } from './AuthContext';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout, login } = useAuth();

  const fetchUser = async () => {
    setLoading(true);
    try {
      const user = await getCurrentUser();
      setCurrentUser(user.user);
      setError(null);
    } catch (error) {
      setError(error);
      logout();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (login) {
      fetchUser();
    }
  }, [login]);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser, loading, error , fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};
