import React, { useEffect, useState } from 'react'
import { Input, SelectVariant } from '../../components'; // Import additional components
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function PacksModal({ formData, setFormData, HandleOnChange, loading, initializeLinks, setModal, Modal, setDone, setLoding, done, id }) {
    const { t } = useTranslation();
    const [featureInput, setFeatureInput] = useState("");

    const HandleOnFeatureInputChange = (e) => {
        setFeatureInput(e.target.value);
    };
    const HandleAddFeature = () => {
        if (!featureInput.trim()) return;

        setFormData((prevData) => ({
            ...prevData,
            features: [...(prevData.features || []), featureInput],
        }));

        setFeatureInput("");
    };

    const HandleDeleteFeature = (indexToDelete) => {
        setFormData((prevData) => ({
            ...prevData,
            features: prevData.features.filter((_, index) => index !== indexToDelete),
        }));
    };


    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true);
            let response = null;
            if (id) {
                response = await axiosInstance.put(`/pack/update/${id}`, formData);
            } else {
                response = await axiosInstance.post('/pack/create', formData);
            }
            const responseData = response.data;
            if (responseData) {
                toast.success(responseData.message);
                if (!id) {
                    initializeLinks();
                }
                setModal(false)
                setDone(!done)
            }
            setLoding(false);
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false);
            console.error('Blog save failed:', error.response.data);
        }
    };


    return (
        <AnimatePresence>

            {Modal &&
                <div
                    className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                    <div className="absolute bg-black opacity-80 inset-0 z-0" />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                        className="w-full md:w-[40%] py-4 relative mx-auto mx-2 h-fit overflow-y-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                        <div className="flex  gap-3 flex-col justify-center items-center w-full px-6 py-3">
                            <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Add Pack : ")} {Modal?.name}</h2>
                                <a onClick={() => setModal(false)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-600/10 text-green-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                            </div>



                            <Input type='text' disabled={false} label={t("Name")} name="name" handleChange={HandleOnChange} defaultV={formData.name || null} />


                            <Input type='number' disabled={false} label={t("Price")} name="price" handleChange={HandleOnChange} defaultV={formData.price || null} />

                            <div className='grid w-full md:grid-cols-2 gap-2'>
                                <Input type='number' disabled={false} label={t("Blogs")} name="blogs" handleChange={HandleOnChange} defaultV={formData.blogs || null} />

                                <Input type='number' disabled={false} label={t("HyperLinks")} name="hyperLinks" handleChange={HandleOnChange} defaultV={formData.hyperLinks || null} />


                            </div>


                            <div className="flex w-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900 rounded-xl justify-start p-3  items-center flex-col">
                                <div className='flex gap-2 w-full'>
                                    <Input
                                        type="text"
                                        disabled={false}
                                        label={t("Feature")}
                                        name="featureInput"
                                        handleChange={HandleOnFeatureInputChange}
                                        defaultV={featureInput}
                                    />
                                    <button
                                        onClick={HandleAddFeature}
                                        className="h-12 flex justify-center mt-1 items-center w-12 bg-black dark:text-black dark:bg-neutral-200 text-neutral-200 rounded-md hover:bg-blue-600 focus:outline-none"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-plus"><circle cx="12" cy="12" r="10" /><path d="M8 12h8" /><path d="M12 8v8" /></svg>
                                    </button>
                                </div>


                                <div className="flex flex-wrap px-10 py-6  justify-center items-start gap-3 w-full">
                                    {formData.features && formData.features.length > 0 ? (
                                        formData.features.map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex border rounded-md justify-start items-center gap-2 font-[300] px-3 py-1 border-neutral-200 dark:border-neutral-700  text-[14px] text-neutral-800 dark:text-neutral-200"
                                            >

                                                <span>{item}</span>

                                                <button
                                                    onClick={() => HandleDeleteFeature(index)}
                                                    className="px-2 py-1 bg-red-500 text-white text-xs rounded-md hover:bg-red-600 focus:outline-none"
                                                >
                                                    {t("Delete")}
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-neutral-500 dark:text-neutral-400">{t("No features added.")}</p>
                                    )}
                                </div>
                            </div>



                            {/* Status Radio Buttons */}
                            <div className="flex flex-col w-full gap-2">
                                <label className="font-bold text-[16px] text-neutral-800 dark:text-neutral-200">Status</label>
                                <div className="flex w-full gap-2">
                                    {["Active", "Inactive"].map((status, index) => (
                                        <label
                                            key={index}
                                            htmlFor={`status-${status.toLowerCase()}`}
                                            className="flex p-3 w-full bg-white border rounded-lg text-sm focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700"
                                        >
                                            <span className="text-sm text-gray-500 dark:text-neutral-400">{status}</span>
                                            <input
                                                type="radio"
                                                name="is_active"
                                                value={status === "Active" ? "true" : "false"}
                                                onChange={HandleOnChange}
                                                checked={formData.is_active === (status === "Active")}
                                                id={`status-${status.toLowerCase()}`}
                                                className="shrink-0 ms-auto mt-0.5 rounded-full text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700"
                                            />
                                        </label>
                                    ))}
                                </div>
                            </div>

                            {/* Popular Radio Buttons */}
                            <div className="flex flex-col w-full gap-2">
                                <label className="font-bold text-[16px] text-neutral-800 dark:text-neutral-200">Popular</label>
                                <div className="flex w-full gap-2">
                                    {["Popular", "Not Popular"].map((popularity, index) => (
                                        <label
                                            key={index}
                                            htmlFor={`popularity-${popularity.toLowerCase().replace(" ", "-")}`}
                                            className="flex p-3 w-full bg-white border rounded-lg text-sm focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700"
                                        >
                                            <span className="text-sm text-gray-500 dark:text-neutral-400">{popularity}</span>
                                            <input
                                                type="radio"
                                                name="is_popular"
                                                value={popularity === "Popular" ? "true" : "false"}
                                                onChange={HandleOnChange}
                                                checked={formData.is_popular === (popularity === "Popular")}
                                                id={`popularity-${popularity.toLowerCase().replace(" ", "-")}`}
                                                className="shrink-0 ms-auto mt-0.5 rounded-full text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-700"
                                            />
                                        </label>
                                    ))}
                                </div>
                            </div>





                            <div className='md:col-span-3 mt-10 w-full flex items-center justify-end '>
                                <div className="flex items-center mt-6 gap-x-3">
                                    <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                        {
                                            !loading ?
                                                (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                        <span>{t("Save Pack")}</span>
                                                    </>
                                                )
                                                :

                                                (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                    <span className="sr-only">Loading...</span>
                                                </div>)

                                        }

                                    </button>
                                </div>
                            </div>

                        </div>
                    </motion.div>
                </div >
            }
        </AnimatePresence >
    )
}

export default PacksModal