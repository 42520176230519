import React, { useEffect, useState } from 'react'
import { SideBareLink } from '../../components'
import { ReactComponent as DashboardIcon } from '../../assets/navbar/ic_analytics.svg';
import { ReactComponent as SettingsIcon } from '../../assets/navbar/ic_settings.svg';
import { ReactComponent as UsersIcon } from '../../assets/navbar/ic_users.svg';
import { ReactComponent as LinksIcon } from '../../assets/navbar/ic_Links.svg';
import { ReactComponent as SuppliersIcon } from '../../assets/navbar/ic_supply.svg';
import { ReactComponent as ProductsIcon } from '../../assets/navbar/ic_products.svg';
import { ReactComponent as RolesIcon } from '../../assets/navbar/ic_roles.svg';
import { ReactComponent as CategoryIcon } from '../../assets/navbar/ic_Category.svg';
import { ReactComponent as PaymentIcon } from '../../assets/navbar/ic_payment.svg';
import { ReactComponent as PromoIcon } from '../../assets/navbar/ic_promo.svg';
import { ReactComponent as PackIcon } from '../../assets/navbar/ic_packs.svg';

import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import photo from '../../assets/images/photo.jpg'
import { motion, AnimatePresence } from 'framer-motion';

const SideBar = ({ open, setOpen }) => {


  const { currentUser, fetchUser } = useCurrentUser();
  const [popover, setPopover] = useState(false)
  const { checkAccess } = useAccessChecker();

  useEffect(() => {
    fetchUser()
  }, [])


  return (
    <>
      <aside className={`lg:flex  z-50 fixed flex-col gap-5 bg-primary  dark:bg-neutral-800 ${open ? 'lg:w-[15%] w-64  px-2  h-[100vh]' : 'lg:w-[6rem] hidden z-20 min-h-screen'}  transition-all duration-300 shadow border-neutral-200 dark:border-neutral-700  border-r border-dashed`}>
        {/* <div className="lg:absolute hidden dark:hidden inset-0 bg-primary z-10 opacity-60"></div>
<div
  className="lg:absolute hidden dark:hidden inset-0 bg-cover rotate-180 bg-center"
  style={{ backgroundImage: `url(${photo})` }}
></div> */}

        <div className={`relative z-50 flex justify-start items-center w-full gap-4  w-[85%] mx-auto border-secondary/30 ${open ? 'border-b ' : ''}  justify-center  items-center py-8`}>

          <a onMouseEnter={() => setPopover(true)}
            onMouseLeave={() => setPopover(false)}
            className='p-2 cursor-pointer rounded-lg bg-secondary '>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-primary">
              <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
            </svg>


          </a>

          {popover &&
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className={` flex flex-col  ${!open ? '' : 'hidden'}  items-start justify-center border border-neutral-200 bg-white z-50 dark:border-neutral-700 dark:bg-neutral-800 px-5 py-3 rounded-lg absolute top-5 left-[6rem] w-fit`}>
              <h1 className='text-[15px] text-[700] text-neutral-900 dark:text-neutral-200'>{currentUser?.fullName}</h1>
              <p className='text-[13px] text-[400] text-neutral-400'>{currentUser?.email}</p>
            </motion.div>
          }

          <div className={` ${open ? '' : 'hidden'} flex flex-col items-start justify-center `}>
            <h1 className='text-[16px] text-[600] text-neutral-200'>{currentUser?.fullName}</h1>
            <p className='text-[13px] text-[400] text-neutral-400'>{currentUser?.email}</p>
          </div>

        </div>

        <div className={`grid  z-50 lg:mt-1 mt-4 place-items-center w-full gap-3  `}>


          {checkAccess('users', 'read') &&
            <SideBareLink path='/' title="dashboard" open={open} icon={<DashboardIcon />} />

          }

          {checkAccess('websites', 'read') &&
            <SideBareLink path='/Websites' title="WebSites" open={open} icon={<ProductsIcon />} />

          }
          {checkAccess('categories', 'read') &&
            <SideBareLink path='/Categories' title="Categories" open={open} icon={<CategoryIcon />} />

          }
          {checkAccess('hyperLink', 'read') &&
            <SideBareLink path='/HyperLinks' title="Hyper Links" open={open} icon={<LinksIcon />} />

          }
          {checkAccess('blogs', 'read') &&
            <SideBareLink path='/blogs' title="Blogs" open={open} icon={<SuppliersIcon />} />

          }
          {checkAccess('packs', 'read') &&
            <SideBareLink path='/Packs' title="Packs" open={open} icon={<PackIcon />} />

          }
          {checkAccess('promoCodes', 'read') &&
            <SideBareLink path='/PromoCodes' title="PromoCodes" open={open} icon={<PromoIcon />} />

          }

          {checkAccess('categories', 'read') &&
            <SideBareLink path='/Balance' title="Balance" open={open} icon={<PaymentIcon />} />

          }
          {checkAccess('users', 'read') &&
            <SideBareLink path='/users' title="users" open={open} icon={<UsersIcon />} />
          }

          {checkAccess('roles', 'read') &&
            <SideBareLink path='/Roles' title="Roles" open={open} icon={<RolesIcon />} />
          }

          {checkAccess('settings', 'read') &&
            <SideBareLink path='/settings' title="settings" open={open} icon={<SettingsIcon />} />
          }


        </div>











      </aside >

      {open &&
        <div onClick={() => setOpen(false)} className='absolute top-0  w-screen h-screen bg-black/10  dark:bg-black/30 z-40 lg:hidden'></div>

      }
    </>
  )
}

export default SideBar
