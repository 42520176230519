import React from 'react'

function SelectVariant({ options, label, handleChange, name, defaultV , disabled}) {


    return (
        <select onChange={(e) => handleChange(e)} name={name} disabled={disabled} value={defaultV} className="py-3 px-4 w-full h-14 border bg-neutral-50  border-neutral-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:focus:ring-neutral-600">
            <option selected disabled>{label}</option>
            {
                options &&
                options.map(item => {
                    return (
                        <option key={item.id} value={item.id} > {item.name} </option>
                    )
                })
            }
        </select>
    )

}

export default SelectVariant