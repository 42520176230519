import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Select } from '../../components';
const ChartTransactions = ({ data, title, label, Clients, onChangeFilter, cnurrentClient }) => {
    const barsRef = useRef();

    useEffect(() => {
        let chartInstance = null;

        // Chart configuration
        const barConfig = {
            type: 'bar',
            data: {
                labels: data?.map(item => item.month),
                datasets: [
                    {
                        label: label,
                        backgroundColor: '#EDFF71',
                        borderWidth: 1,
                        data: data?.map(item => item.count),
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false, // Ensure chart respects fixed dimensions
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        };

        if (barsRef.current) {
            const barsCtx = barsRef.current.getContext('2d');
            chartInstance = new Chart(barsCtx, barConfig);
        }

        return () => {
            if (chartInstance) {
                chartInstance.destroy();
            }
        };
    }, [data]);

    return (
        <div className="charts p-7 bg-white rounded-xl shadow-xs dark:bg-neutral-800 w-full shadow-lg">
            <div className='flex w-full justify-between items-center'>
                <div className='flex justify-start w-1/2'>
                    <h4 className="mb-4 font-semibold text-theme  dark:text-gray-300">{title}</h4>

                </div>

            </div>

            <div className="chart-container" style={{ height: '500px', width: '100%' }}>
                <canvas id="bars" ref={barsRef}></canvas>
            </div>

        </div>
    );
};

export default ChartTransactions;
