import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Loading, SelectVariant, Select } from '../../components';
import { DeleteModal } from '../../components';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import excelImage from '../../assets/images/excel.png'

function Categories() {
    const { t } = useTranslation();

    const { checkAccess } = useAccessChecker();

    const [CategoryModal, setCategoryModal] = useState(false)
    const [CategoryId, setCategoryId] = useState(null)


    const [searchValue, setSearchValue] = useState(null)

    const [formDataImport, setFormDataImport] = useState({ file: null, website: null })

    const [formData, setFormData] = useState({ name: null, website: null })
    const [loading, setLoding] = useState(false)
    const [Category, setCategory] = useState([])
    const [done, setDone] = useState(false)
    const [ImportCategoryModal, setImportCategoryModal] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)
    const [Website, setWebsite] = useState([])

    const [filter, setFilter] = useState({ website_id: null })


    useEffect(() => {
        getCategory()
        getWebsite()
    }, [CategoryId, done])

    const getWebsite = async () => {
        try {
            const response = await axiosInstance.get('/websites/search');
            const responseData = response.data

            if (responseData) {
                setWebsite(responseData.data)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    };


    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getCategory = async (page = 1) => {
        try {
            setLoding(true);
            const response = await axiosInstance.post(`/categories/search?page=${page}&per_page=10`, filter);
            const responseData = response.data;

            if (responseData) {
                setCategory(responseData.data);
                setCurrentPage(responseData.current_page);
                setTotalPages(responseData.last_page);
            }
            setLoding(false);
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            getCategory(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            getCategory(currentPage - 1);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleChangeImport = (e) => {
        const { name, type, files, value } = e.target;
        if (type === 'file') {
            const file = files[0];

            if (!file) {
                console.error("No file selected");
                return;
            }

            const validExtensions = ['.xls', '.xlsx'];
            const validMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

            const isValidExtension = validExtensions.some(extension => file.name.toLowerCase().endsWith(extension));
            const isValidMimeType = validMimeTypes.includes(file.type);

            if (!isValidExtension && !isValidMimeType) {
                toast.error("Please upload a valid Excel file (.xls or .xlsx).");
                return;
            }

            setFormDataImport(prevState => ({
                ...prevState,
                [name]: file
            }));
        } else {
            setFormDataImport(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const InitialiseCategory = () => {
        setFormData({ name: null })
    }

    const handleToggleModal = (id) => {
        if (id) {
            setCategoryId(id)
            getCategoryToUpdate(id)

        } else {
            setCategoryModal(!CategoryModal)
            InitialiseCategory()

        }
    }

    const getCategoryToUpdate = async (id) => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/categories/get/${id}`);
            const responseData = response.data
            if (responseData) {
                setFormData(responseData.data)
                setCategoryModal(!CategoryModal)

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    }
    const find = async (e) => {
        try {
            e.preventDefault()
            setLoding(true)
            const response = await axiosInstance.post(`/categories/find`, { searchValue });
            const responseData = response.data

            if (responseData) {
                setCategory(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            if (CategoryId) {
                response = await axiosInstance.put(`/categories/update/${CategoryId}`, formData);
            } else {
                response = await axiosInstance.post('/categories/create', formData);
            }
            const responseData = response.data
            if (responseData) {
                if (CategoryId) {
                    toast.success(responseData.message);
                    setCategoryId(null)
                    setCategoryModal(false)
                    InitialiseCategory()
                    setDone(!done)
                } else {
                    toast.success(responseData.message);
                    handleToggleModal()
                    setDone(!done)
                }
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
        }
    };



    const saveImport = async (e) => {
        e.preventDefault();

        if (!formDataImport.file) {
            toast.error("Please select a file.");
            return;
        }

        if (!formDataImport.website) {
            toast.error("Please select a website.");
            return;
        }

        try {
            setLoding(true);
            const formData = new FormData(); // Create a new FormData object
            formData.append('file', formDataImport.file); // Append the file
            formData.append('website', formDataImport.website); // Append other data if needed

            const response = await axiosInstance.post('/categories/importCategories', formData);
            const responseData = response.data;

            if (responseData) {
                toast.success(responseData.message);
                setImportCategoryModal(false);
                setDone(!done);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Error occurred during file upload.");
        } finally {
            setLoding(false);
        }
    };


    const reset = () => {
        setSearchValue('')
        getCategory()
        InitialiseCategory()
        setFilter({ website_id: null })
    }


    const filterCategory = () => {
        getCategory()
    }


    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }


    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            }}
            className="flex flex-col w-full justify-start md:p-6 p-2 h-[100vh]">
            {loading &&
                <Loading />
            }
            <section className=" w-full mx-auto">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex  items-center gap-x-3">
                            <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Categories</h2>
                            <span className="px-3 py-1 text-xs dark:bg-secondary/10 dark:text-secondary rounded-full text-primary bg-primary/10">{Category.length} Category</span>
                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">These companies have purchased in the last 12 months.</p>
                    </div>
                    <div className="flex items-center mt-4 gap-x-3">

                        {checkAccess('categories', 'create') &&

                            <button onClick={() => { handleToggleModal() }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>Add Category</span>
                            </button>
                        }


                        {checkAccess('categories', 'create') &&

                            <button onClick={() => { setImportCategoryModal(true) }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <img src={excelImage} alt='excel image' className='w-5 h-5' />
                                <span>Import Categories</span>
                            </button>
                        }

                    </div>
                </div>
                <div className="mt-6 md:flex md:items-center w-full md:justify-between">

                    <form className="flex items-center md:w-1/3 w-full  ">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute cursor-pointer z-50 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg onClick={(e) => reset(e)} className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2" />
                                </svg>
                            </div>
                            <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} id="simple-search" className="peer h-11 block w-full border bg-neutral-50  border-neutral-200 rounded-lg text-sm  pl-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600" placeholder="Search branch name..." required />
                        </div>
                        <button type="submit" onClick={(e) => find(e)} disabled={!searchValue} className="h-[2.6rem] w-[2.6rem] px-3 flex justify-center items-center ms-2 text-sm font-medium text-white  rounded-lg text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </form>
                    <div className='flex justify-end border rounded-lg mt-3 border-gray-200 dark:border-neutral-700 p-2 dark:bg-neutral-800 items-center gap-3 md:flex-row flex-col'>
                        <Select label="Website" options={Website} width={11} name="website_id" handleChange={handleChange} defaultV={filter.website_id || null} />
                        <div className='flex items-center gap-3 '>
                            <button onClick={(e) => filterCategory(e)} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                </svg>


                                <span>Filter</span>
                            </button>
                            <button onClick={reset} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>


                                <span>Reset</span>
                            </button>

                        </div>

                    </div>
                </div>
                <div className="flex flex-col mt-6">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden border border-neutral-200 dark:border-neutral-700 md:rounded-lg">
                                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                                    <thead className="bg-neutral-50 dark:bg-neutral-800">
                                        <tr>
                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Website
                                            </th>

                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Name
                                            </th>

                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Date
                                            </th>
                                            {
                                                (checkAccess('categories', 'update') || checkAccess('categories', 'delete')) &&

                                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">Actions</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200 text-start dark:divide-neutral-700 dark:bg-neutral-900">
                                        {
                                            Category.length > 0 &&
                                            Category.map(item => {
                                                return (
                                                    <tr className='h-[4rem]'>
                                                        <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                            <div>
                                                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.website?.name}</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                            <div>
                                                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.name}</p>
                                                            </div>
                                                        </td>


                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{formatDate(item.created_at)}</p>
                                                            </div>
                                                        </td>
                                                        {
                                                            (checkAccess('categories', 'update') || checkAccess('categories', 'delete')) &&

                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className='flex justify-start items-center gap-2'>
                                                                    {checkAccess('categories', 'delete') &&

                                                                        <a onClick={() => { setCategoryId(item.id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                                                        </a>
                                                                    }
                                                                    {checkAccess('categories', 'update') &&

                                                                        <a onClick={() => handleToggleModal(item.id)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                                        </a>
                                                                    }

                                                                </div>
                                                            </td>
                                                        }

                                                    </tr>
                                                )
                                            })
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
                    <div className="text-sm text-neutral-500 dark:text-neutral-400">
                        Page <span className="font-medium text-neutral-700 dark:text-neutral-100">{currentPage} of {totalPages}</span>
                    </div>
                    <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                        <button
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>
                            <span>Previous</span>
                        </button>
                        <button
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <span>Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>

            </section>



            <AnimatePresence>

                {CategoryModal &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="w-full md:w-[40%] py-4 relative mx-auto mx-2 h-fit overflow-y-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                            <div className="flex  gap-3 flex-col justify-center items-center w-full px-6 py-3">
                                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                    <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Add Category")}</h2>
                                    <a onClick={() => handleToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-600/10 text-green-600'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                                </div>
                                <SelectVariant label="Website" options={Website} name="website" disabled={false} handleChange={handleChange} defaultV={formData.website?.id || null} />

                                <Input type='text' disabled={false} label={t("Name")} name="name" handleChange={handleChange} defaultV={formData.name || null} />




                                <div className='md:col-span-3 mt-10 w-full flex items-center justify-end '>
                                    <div className="flex items-center mt-6 gap-x-3">
                                        <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            {
                                                !loading ?
                                                    (
                                                        <>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            <span>{t("Save category")}</span>
                                                        </>
                                                    )
                                                    :

                                                    (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>)

                                            }

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence >



            <AnimatePresence>

                {ImportCategoryModal &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="w-full md:w-[40%] py-4 relative mx-auto mx-2 h-fit overflow-y-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                            <div className="flex  gap-3 flex-col justify-center items-center w-full px-6 py-3">
                                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                    <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Add Category")}</h2>
                                    <a onClick={() => setImportCategoryModal(false)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-600/10 text-green-600'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                                </div>
                                <SelectVariant label="Website" options={Website} name="website" disabled={false} handleChange={handleChangeImport} defaultV={formDataImport.website?.id || null} />

                                <div className="w-full">
                                    <label htmlFor="file" className="sr-only">Choose file</label>
                                    <input type="file" name="file" id="file" onChange={(e) => handleChangeImport(e)} className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400
    file:bg-gray-50 file:border-0
    file:me-4
    file:py-3 file:px-4
    dark:file:bg-neutral-700 dark:file:text-neutral-400" />
                                </div>




                                <div className='md:col-span-3 mt-10 w-full flex items-center justify-end '>
                                    <div className="flex items-center mt-6 gap-x-3">
                                        <button onClick={(e) => saveImport(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            {
                                                !loading ?
                                                    (
                                                        <>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            <span>{t("Import")}</span>
                                                        </>
                                                    )
                                                    :

                                                    (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>)

                                            }

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence >


            <DeleteModal setDeleteId={setCategoryId} open={deleteModal} setOpen={setDeleteModal} DeleteId={CategoryId} Model='categories' setDone={setDone} setLoding={setLoding} done={done} />

        </motion.div >


    )
}

export default Categories