import React, { useRef, useState, useEffect } from 'react'
import axiosInstance from '../../utils/axios/axiosConfig';
import { useAuth } from '../../utils/context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { ForgetPassChange } from '../../components';
import { toast } from 'react-toastify';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import photo from '../../assets/images/photo.jpg'

import logoArab from '../../assets/images/logoArab.png'
import logoEnglish from '../../assets/images/logoEnglish.png'
import logoFrench from '../../assets/images/logoFrench.png'


function Login() {

  const { currentUser } = useCurrentUser();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const { login } = useAuth();
  const [loginData, setLoginData] = useState({ email: null, password: null, code: null })
  const [loading, setLoding] = useState(false)
  const [codeVerify, setCodeVerify] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [SignUp, setSignUp] = useState(false)

  const [redirectLoading, setRedirectLoading] = useState(false);
  const [forgetPassword, setForgetPaasword] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const [clientRole, setclientRole] = useState({});

  const apiSanctum = process.env.REACT_APP_BACKEND_URL_SANCTUM

  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);

  const [formData, setFormData] = useState({ doubleAuth: true, email: null, fullName: null, phone: null, role_id: null, etat: null, fonction: null, password: null })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };


  const handleInput = (e, index) => {
    const value = e.target.value;
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode.join(''));
    setLoginData({ ...loginData, code: newVerificationCode.join('') })

  };
  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (paste.length === 6 && /^[0-9]*$/.test(paste)) {
      const newVerificationCode = paste.split('');
      setVerificationCode(newVerificationCode);
      setLoginData({ ...loginData, code: paste });
    }
    e.preventDefault();
  };


  const InitialiseUser = () => {
    setFormData({ doubleAuth: true, email: null, fullName: null, role_id: null, phone: null, etat: null, password: null })
  }

  useEffect(() => {
    navigate('/Login')
    axios.get(`${apiSanctum}/csrf-cookie`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",


      },
      withCredentials: true,
    })
  }, [])

  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginData.email || !loginData.password) {
      setErrorMessage('Both email and password are required.');
      return;
    }
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/login', loginData);
      const responseData = response.data

      if (responseData && responseData.success) {
        setCodeVerify(true)
      }
      else if (responseData && responseData.user) {
        const { token } = responseData;
        login(token)
        setRedirectLoading(true);
        setTimeout(() => {
          setRedirectLoading(false);
          navigate('/');
        }, 2000);
      }
      setLoding(false)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error.response.data.message)

      setLoding(false)
    }
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    if (!loginData.code) {
      setErrorMessage('The code is required.');
      return;
    }
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/code-verification', loginData);
      const responseData = response.data
      if (responseData && responseData.user) {
        const { token } = responseData;
        setCodeVerify(false)
        login(token)
        setRedirectLoading(true);
        setTimeout(() => {
          setRedirectLoading(false);
          navigate('/');
        }, 2000);
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
      console.error('Login failed:', error.response.data);
    }
  };


  const handleFrogetPassword = async (e) => {
    e.preventDefault();

    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/forget-passKey', loginData);
      const responseData = response.data
      if (responseData ) {
        setCodeVerify(true)
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
      console.error('Login failed:', error.response.data);
    }
  };

  const handleForgetPassCodeVerification = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.post('/auth/verify-code', loginData);
      const responseData = response.data
      if (responseData) {
        setCodeVerify(false)
        setChangePasswordModal(true)
        setForgetPaasword(false)
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoding(false)
      console.error('Login failed:', error.response.data);
    }
  };


  const Register = async (e) => {
    e.preventDefault();

    if (formData.passwordConfirm != formData.password) {
      setErrorMessage('Confirm password is incorrect.')
      return;
    }

    try {
      setLoding(true)
      const response = await axiosInstance.post('/users/register', formData);
      const responseData = response.data
      if (responseData) {
        InitialiseUser()
        toast.success(responseData.message);
        setSignUp(false)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false)
      console.error('User creation failed:', error.response.data);
    }
  };



  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.05 }} 
      animate={{ opacity: 1, scale: 1 }} 
      exit={{ opacity: 0, scale: 0.95 }} g
      transition={{
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1], 
      }} className="w-screen h-screen flex bg-white relative dark:bg-neutral-900 items-center justify-center relative overflow-x-hidden">
      {redirectLoading ?

        (
          <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-black dark:text-white rounded-xl" role="status" aria-label="loading">
            <span className="sr-only">Loading...</span>
          </div>
        )

        :

        (
          <div className="flex justify-center  items-center w-full h-full">

            <div className='bg-primary dark:bg-neutral-900 absolute h-screen w-[100%] md:w-[50%] left-0'>
              <div className="absolute inset-0 bg-primary z-10 opacity-60"></div>
              <div
                className="absolute inset-0 bg-cover rotate-180 bg-center"
                style={{ backgroundImage: `url(${photo})` }}
              ></div>

            </div>

            <div className="flex items-center justify-center z-40  h-full md:w-1/2 w-full flex-col    my-5">
              <div className="flex items-center   w-[96%] sm:w-[90%] md:w-[85%] lg:w-[75%] xl:w-[65%] 2xl:w-[55%]  border px-4 sm:px-6 md:px-8 lg:px-10 md:py-10 py-3 rounded-3xl bg-white dark:bg-neutral-800 dark:border-neutral-700 shadow-lg xl:p-10">
                <form className="flex flex-col w-full h-full  text-start   rounded-full">
                  <div className='w-full flex justify-center w-full items-center px-6'>
                    
                  </div>

                  {!forgetPassword ?
                    (
                      !SignUp ?

                        <div>

                          <div className='flex justify-center gap-3 flex-col items-start py-5 mb-4'>
                            <h3 className=" text-4xl  font-bold text-dark-neutral-800 dark:text-gray-300">Sign In</h3>
                            <p className=" text-gray-700 dark:text-gray-400">Enter your email and password</p>
                          </div>
                          {errorMessage &&
                            <div className='flex mb-3 justify-start items-center gap-3 px-4 py-4 bg-primary/10 border border-primary rounded-lg'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide  text-primary lucide-circle-alert"><circle cx="12" cy="12" r="10" /><line x1="12" x2="12" y1="8" y2="12" /><line x1="12" x2="12.01" y1="16" y2="16" /></svg>
                              <p className='text-primary font-[500] text-[13px] '>{errorMessage}</p>
                            </div>
                          }
                          <div className="flex flex-col">
                            <label htmlFor="email" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Email*</label>
                            <input id="email" type="email" onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} placeholder="mail@loopple.com" className="flex items-center w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                          </div>
                          <div className="relative flex flex-col mb-2">
                            <label htmlFor="password" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Password*</label>
                            <input id="password" type={`${passwordToggle ? 'text' : 'password'}`} onChange={(e) => setLoginData({ ...loginData, password: e.target.value })} placeholder="Enter a password" className="flex items-center  w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                            {!passwordToggle ?
                              <svg onClick={() => setPasswordToggle(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                              :
                              <svg onClick={() => setPasswordToggle(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                              </svg>
                            }
                          </div>

                        </div>

                        :

                        <div>

                          <div className='flex justify-center gap-3 flex-col items-start py-5 mb-4'>
                            <h3 className=" text-4xl  font-bold text-dark-neutral-800 dark:text-gray-300">Sign Up</h3>
                            <p className=" text-gray-700 dark:text-gray-400">Boost your site's rankings with powerful backlinks. Join us for expert link-building and drive more traffic today!</p>
                          </div>
                          {errorMessage &&
                            <div className='flex mb-3 justify-start items-center gap-3 px-4 py-4 bg-primary/10 border border-primary rounded-lg'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide  text-primary lucide-circle-alert"><circle cx="12" cy="12" r="10" /><line x1="12" x2="12" y1="8" y2="12" /><line x1="12" x2="12.01" y1="16" y2="16" /></svg>
                              <p className='text-primary font-[500] text-[13px] '>{errorMessage}</p>
                            </div>
                          }

                          <div className="flex flex-col">
                            <label htmlFor="fullName" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Full Name*</label>
                            <input id="fullName" type="text" onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} placeholder="mail@loopple.com" className="flex items-center w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                          </div>


                          <div className="flex flex-col">
                            <label htmlFor="phone" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Phone Number*</label>
                            <input id="phone" type="text" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} placeholder="mail@loopple.com" className="flex items-center w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                          </div>

                          <div className="flex flex-col">
                            <label htmlFor="email" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Email*</label>
                            <input id="email" type="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} placeholder="mail@loopple.com" className="flex items-center w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                          </div>


                          <div className="relative flex flex-col mb-2">
                            <label htmlFor="password" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Password*</label>
                            <input id="password" type={`${passwordToggle ? 'text' : 'password'}`} onChange={(e) => setFormData({ ...formData, password: e.target.value })} placeholder="Enter a password" className="flex items-center  w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                            {!passwordToggle ?
                              <svg onClick={() => setPasswordToggle(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                              :
                              <svg onClick={() => setPasswordToggle(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                              </svg>
                            }
                          </div>

                          <div className="relative flex flex-col mb-2">
                            <label htmlFor="password" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Confirm Password *</label>
                            <input id="password" type={`${passwordToggle ? 'text' : 'password'}`} onChange={(e) => setFormData({ ...formData, passwordConfirm: e.target.value })} placeholder="Enter a password" className="flex items-center  w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                            {!passwordToggle ?
                              <svg onClick={() => setPasswordToggle(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>
                              :
                              <svg onClick={() => setPasswordToggle(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute w-5 h-5 right-3 text-gray-500 top-[45%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                              </svg>
                            }
                          </div>

                        </div>


                    )




                    :
                    <div>
                      <div className='flex justify-center gap-3 flex-col items-center py-5 mb-4'>
                        <h3 className=" text-4xl  font-bold text-dark-neutral-800 dark:text-gray-300">Forget Password</h3>
                        <p className=" text-gray-700 dark:text-gray-400">Enter your email here to reset your password</p>
                      </div>
                      {errorMessage &&
                        <div className='flex mb-3 justify-start items-center gap-3 px-4 py-4 bg-primary/10 border border-primary rounded-xl'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide  text-primary lucide-circle-alert"><circle cx="12" cy="12" r="10" /><line x1="12" x2="12" y1="8" y2="12" /><line x1="12" x2="12.01" y1="16" y2="16" /></svg>
                          <p className='text-primary font-[500] text-[13px] '>{errorMessage}</p>
                        </div>
                      }
                      <div className="flex flex-col my-3">
                        <label htmlFor="email" className="mb-2 text-sm text-start text-gray-800 dark:text-gray-300">Email*</label>
                        <input id="email" type="email" onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} placeholder="mail@loopple.com" className="flex items-center w-full px-5 py-4 mb-3 mr-2 text-sm font-medium outline-none hover:bg-neutral-100 placeholder:text-gray-400 bg-neutral-50 border text-gray-800 rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
                      </div>



                    </div>
                  }
                  <button onClick={(e) => { forgetPassword ? handleFrogetPassword(e) : SignUp ? Register(e) : handleLogin(e) }} className="w-full px-6 py-5  text-sm font-bold leading-none text-white transition duration-300  rounded-xl hover:bg-primary focus:ring-4 focus:ring-primary-blue-100 bg-primary dark:bg-primary">{
                    !loading ? forgetPassword ? 'Verify' : SignUp ? 'Register' : 'Sign In'
                      : (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-xl" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                      </div>)}
                  </button>

                  {!SignUp ?
                    (
                      !forgetPassword ?
                        <div className='flex mt-3 justify-between items-center w-full'>
                          <a onClick={() => { setForgetPaasword(false); setSignUp(true) }} className="text-center  cursor-pointer text-sm font-medium text-blue-500 underline">Return To , Sign Up ?</a>
                          <a onClick={() => setForgetPaasword(true)} className=" cursor-pointer text-sm font-medium text-blue-500 underline">Forget password?</a>

                        </div>

                        :
                        <div className='flex mt-3 justify-between items-center w-full'>
                          <a onClick={() => { setForgetPaasword(false); setSignUp(true) }} className="text-center  cursor-pointer text-sm font-medium text-blue-500 underline">Return To , Sign Up ?</a>

                        </div>
                    )
                    :
                    (
                      !forgetPassword ?
                        <div className='flex mt-3 justify-between items-center w-full'>
                          <a onClick={() => { setForgetPaasword(false); setSignUp(false) }} className="text-center  cursor-pointer text-sm font-medium text-blue-500 underline">Return To , Sign In ?</a>
                          <a onClick={() => setForgetPaasword(true)} className="  cursor-pointer text-sm font-medium text-blue-500 underline">Forget password?</a>

                        </div>

                        :
                        <div className='flex mt-3 justify-between items-center w-full'>
                          <a onClick={() => { setForgetPaasword(false); setSignUp(false) }} className="text-center  cursor-pointer text-sm font-medium text-blue-500 underline">Return To , Sign In ?</a>

                        </div>
                    )
                  }


                </form>
              </div>
            </div>
          </div >
        )
      }


      {
        codeVerify &&

        <div className="relative transition-all duration-500 z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="w-fit px-9 py-3 flex flex-col bg-white border shadow-sm rounded-2xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
                <div className='flex justify-center items-center flex-col items-start py-4'>
                  <h3 className="mb-3 text-3xl  font-bold text-dark-neutral-800 dark:text-gray-300">Verify Code</h3>
                  <p className="mb-4 text-gray-700 dark:text-gray-400">Enter your verification code received in email</p>
                </div>
                {errorMessage &&
                  <div className='flex mb-3 justify-start items-center gap-3 px-4 py-4 bg-primary/10 border border-primary rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide  text-primary lucide-circle-alert"><circle cx="12" cy="12" r="10" /><line x1="12" x2="12" y1="8" y2="12" /><line x1="12" x2="12.01" y1="16" y2="16" /></svg>
                    <p className='text-primary font-[500] text-[13px] '>{errorMessage}</p>
                  </div>
                }
                <div className="flex w-full justify-center  space-x-3 mb-7" data-hs-pin-input>
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      className="block size-[46px] border-2 text-center border-neutral-200 rounded-md text-sm [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                      placeholder="⚬"
                      value={verificationCode[index]}
                      onChange={(e) => handleInput(e, index)}
                      onPaste={handlePaste}
                    />
                  ))}
                </div>
                <div className="flex justify-center w-full items-center  ">

                  <button onClick={(e) => { !forgetPassword ? handleCodeVerification(e) : handleForgetPassCodeVerification(e) }} className="w-full px-6 py-5 mb-3 text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-lg hover:bg-primary focus:ring-4 focus:ring-primary bg-primary dark:bg-primary">{
                    !loading ? 'Verify'
                      : (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-xl" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                      </div>)}
                  </button>
                </div>


              </div>
            </div>
          </div>
        </div>




      }

      {
        changePasswordModal &&
        <ForgetPassChange email={loginData.email} setChangePasswordModal={setChangePasswordModal} code={loginData.code} />

      }


    </motion.div >
  )
}

export default Login


