import { Routes, Route } from 'react-router-dom';
import { Login, Dashboard, Users, Roles, FormRoles, Blogs, FormBlogs, NotFoundPage, FormUsers, Settings, Websites, HyperLinks, Categories, Balance, Packs, PromoCodes } from '../pages';
import { MainLayout } from '../layout';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';



const Routers = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* **************************************Roles*Start******************************************* */}
        <Route
          path="/Roles"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Roles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Roles/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRoles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Roles/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRoles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* **************************************Roles*End******************************************* */}

        {/* **************************************Users*Start******************************************* */}

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Users />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/users/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Users*End******************************************* */}

        {/* **************************************Blogs*Start******************************************* */}

        <Route
          path="/blogs"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Blogs />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/blogs/New/:website_id/:name"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormBlogs />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormBlogs />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Blogs*End******************************************* */}

        <Route
          path="/Websites"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Websites />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Balance"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Balance />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Categories"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Categories />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/HyperLinks"
          element={
            <ProtectedRoute>
              <MainLayout>
                <HyperLinks />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/PromoCodes"
          element={
            <ProtectedRoute>
              <MainLayout>
                <PromoCodes />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Packs"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Packs />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default Routers;
