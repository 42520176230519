import React, { useEffect, useState } from 'react'
import { Input, SelectVariant } from '../../components'; // Import additional components
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function LinksModal({ formDataLinks, HandleOnChange,loading , initializeLinks, setModalLinks, ModalLinks, setDone, setLoding, done, id }) {
    const { t } = useTranslation();

    const [Categories, setCategories] = useState([])
    const Types = [{ name: 'Follow', _id: 'Follow' }, { name: 'NoFollow', _id: 'NoFollow' }]


    useEffect(() => {
        getCategories()

    }, [formDataLinks.website])

    const getCategories = async () => {
        try {
            const response = await axiosInstance.post('/categories/search' , {website_id : formDataLinks.website});
            const responseData = response.data

            if (responseData) {
                setCategories(responseData.data)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    };

    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true);
            let response = null;
            if (id) {
                response = await axiosInstance.put(`/hyperLink/update/${id}`, formDataLinks);
            } else {
                response = await axiosInstance.post('/hyperLink/create', formDataLinks);
            }
            const responseData = response.data;
            if (responseData) {
                toast.success(responseData.message);
                if (!id) {
                    initializeLinks();
                }
                setModalLinks(null)
                setDone(!done)
            }
            setLoding(false);
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false);
            console.error('Blog save failed:', error.response.data);
        }
    };


    return (
        <AnimatePresence>

            {ModalLinks &&
                <div
                    className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                    <div className="absolute bg-black opacity-80 inset-0 z-0" />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                        className="w-full md:w-[40%] py-4 relative mx-auto mx-2 h-fit overflow-y-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                        <div className="flex  gap-3 flex-col justify-center items-center w-full px-6 py-3">
                            <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Add Hyper Link : ")} {ModalLinks?.name}</h2>
                                <a onClick={() => setModalLinks(null)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-600/10 text-green-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                            </div>

                            <SelectVariant label="Category" options={Categories} name="category" disabled={id ? true : false} handleChange={HandleOnChange} defaultV={formDataLinks.category?.id || null} />


                            <Input type='text' disabled={false} label={t("Url")} name="url" handleChange={HandleOnChange} defaultV={formDataLinks.url || null} />



                            <Input type='text' disabled={false} label={t("Anchor")} name="anchor" handleChange={HandleOnChange} defaultV={formDataLinks.anchor || null} />


                            <SelectVariant label="Type" options={Types} name="type" disabled={false} handleChange={HandleOnChange} defaultV={formDataLinks.type || null} />

                            <div className='flex flex-col w-full justify-center items-start gap-2'>
                                <label className='font-[700] text-[16px]'>Schedule</label>
                                <input
                                    type="datetime-local"
                                    className="peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600"
                                    name="schedule_at"
                                    onChange={(e) => HandleOnChange(e)}
                                    defaultValue={formDataLinks.schedule_at ? formDataLinks.schedule_at.substring(0, 16) : null} // Format to 'YYYY-MM-DDTHH:MM'
                                />
                            </div>

                            <div className='md:col-span-3 mt-10 w-full flex items-center justify-end '>
                                <div className="flex items-center mt-6 gap-x-3">
                                    <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                        {
                                            !loading ?
                                                (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                        <span>{t("Save Hyper Link")}</span>
                                                    </>
                                                )
                                                :

                                                (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                    <span className="sr-only">Loading...</span>
                                                </div>)

                                        }

                                    </button>
                                </div>
                            </div>

                        </div>
                    </motion.div>
                </div>
            }
        </AnimatePresence >
    )
}

export default LinksModal