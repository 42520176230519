import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteModal, Loading } from '../../components';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import avatar from '../../assets/images/avatar.jpg'
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { motion, AnimatePresence } from 'framer-motion';


function Users() {
  const { checkAccess } = useAccessChecker();

  const [loading, setLoding] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [users, setUsers] = useState([])
  const [userSelected, setUserSelected] = useState(null)


  const [userId, setUserId] = useState(null)
  const [done, setDone] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)


  useEffect(() => {
    getUsers()
  }, [done])


  const getUsers = async () => {
    try {
      setErrorMessage('')
      setLoding(true)
      const response = await axiosInstance.get('/users/search');
      const responseData = response.data

      if (responseData) {
        setErrorMessage('')
        setUsers(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      toast.error(error.response.data.message)
      setLoding(false)
      console.error('user creation failed:', error.response.data);
    }
  };



  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
      animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
      exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
      transition={{
        duration: 0.5, // Shorter duration for quicker transitions
        ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
      }} className="flex flex-col w-[100%] justify-start md:p-6 p-2  h-[100vh]">
      {loading &&
        <Loading />
      }
      <div className="sm:flex sm:items-center mb-6 sm:justify-between">
        <div>
          <div className="flex items-center gap-x-3">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Users</h2>
            <span className="px-3 py-1 text-xs text-primary bg-primary/10 rounded-full dark:bg-secondary/10 dark:text-secondary">{users.length} Users</span>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">These companies have purchased in the last 12 months.</p>
        </div>
        <div className="flex items-center mt-4 gap-x-3">
          {checkAccess('users', 'create') &&
            <Link to='/Users/new' className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>Add User</span>
            </Link>
          }

        </div>
      </div>
      <div className='w-full py-6 grid md:grid-cols-5 gap-3  grid-cols-1'>
        {users.length > 0 ?

          users.map(user => {
            return (
              <div className='relative flex justify-center border items-center bg-white dark:bg-neutral-800 w-full border-neutral-200 dark:border-neutral-700 p-4 rounded-xl'>

                {(checkAccess('users', 'update') || checkAccess('users', 'delete')) &&
                  <div className="absolute z-50 top-2 right-2 text-right">
                    <Menu>
                      <MenuButton className="inline-flex flex justify-center items-center  p-1 rounded w-7 h-7  items-center gap-2 dark:text-gray-200 text-gray-800 bg-neutral-100 dark:bg-neutral-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                        </svg>
                      </MenuButton>
                      <Transition
                        enter="transition ease-out duration-75"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <MenuItems
                          anchor="bottom end"
                          className="w-52 origin-top-right rounded-xl border border-neutral-200 p-1  dark:border-neutral-700 bg-white dark:bg-neutral-900 p-1 text-sm/6 dark:text-gray-200 text-gray-800 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                        >
                          {checkAccess('users', 'update') &&
                            <MenuItem>
                              <Link to={`/Users/update/${user.id}`} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                Edit
                                <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘E</kbd>
                              </Link>
                            </MenuItem>
                          }

                          {checkAccess('users', 'delete') &&
                            <MenuItem>
                              <button onClick={(e) => { setUserId(user.id); setDeleteModal(true) }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>                            Delete
                                <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 text-white/50 group-data-[focus]:inline">⌘D</kbd>
                              </button>
                            </MenuItem>
                          }
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                }

                <div className='grid place-items-center gap-2'>
                  <div className="relative inline-block">
                    <img className="inline-block size-[62px] rounded-full" src={user.image ? user.image : avatar} alt="Image Description" />
                    <span className="absolute top-0 end-0 block size-3.5 rounded-full ring-2 ring-white bg-yellow-400 dark:ring-neutral-900" />
                  </div>
                  <div className='grid place-items-center gap-1'>
                    <h5 className="block  text-xl font-semibold leading-snug tracking-normal text-green-600-neutral-900 dark:text-gray-300 ">
                      {user.fullName}
                    </h5>
                    <p className="block dark:text-gray-400  font-[300] leading-relaxed text-[14px] antialiased">
                      {user.email}
                    </p>
                    <p className="block dark:bg-secondary/10 dark:text-secondary text-primary bg-primary/10 rounded-lg px-3 py-1 mt-2 font-[600] leading-relaxed text-[15px]  antialiased">
                      {user.role?.name}
                    </p>
                  </div>

                </div>
              </div>
            )
          })


          :
          (
            <div className="min-h-60 md:col-span-5 flex flex-col bg-white border w-[100%] shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
              <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                <svg className="size-10 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round">
                  <line x1={22} x2={2} y1={12} y2={12} />
                  <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
                  <line x1={6} x2="6.01" y1={16} y2={16} />
                  <line x1={10} x2="10.01" y1={16} y2={16} />
                </svg>
                <p className="mt-2 text-sm text-gray-800 dark:text-gray-300">
                  No data to show
                </p>
              </div>
            </div>

          )
        }
      </div>

      <DeleteModal setDeleteId={setUserId} open={deleteModal} setOpen={setDeleteModal} DeleteId={userId} Model='users' setDone={setDone} setLoding={setLoding} done={done} />


    </motion.div>

  )
}

export default Users