import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Select } from '../../components';
function ChartsRestock({ data, title, label, Suppliers, onChangeFilter, cnurrentSupplier }) {
    const lineRef = useRef();

    useEffect(() => {
        // Chart configuration
        const lineConfig = {
            type: 'line',
            data: {
                labels: data?.map(item => item.month),
                datasets: [
                    {
                        label: label,
                        fill: false,
                        backgroundColor: '#EDFF71',
                        borderColor: '#EDFF71',
                        data: data?.map(item => item.count),
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false, // Ensure chart respects fixed dimensions
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                    },
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Month',
                        },
                    },
                    y: {
                        display: true,
                        beginAtZero: true, // Ensure y-axis starts at 0
                        scaleLabel: {
                            display: false,
                            labelString: 'Value',
                        },
                    },
                },
            },
        };

        if (lineRef.current) {
            const myLine = new Chart(lineRef.current, lineConfig);

            return () => {
                myLine.destroy();
            };
        }
    }, [data, label]);

    return (
        <div className="charts p-7 bg-white rounded-xl shadow-xs w-full h-full dark:bg-neutral-800 shadow-lg">
            <div className='flex w-full justify-between items-center'>
                <div className='flex justify-start w-1/2'>
                    <h4 className="mb-4 font-semibold text-theme  dark:text-gray-300">{title}</h4>

                </div>

            </div>
            <div className="chart-container" style={{ maxHeight: '500px', height: '100%', width: '100%' }}>
                <canvas id="line" ref={lineRef}></canvas>
            </div>

        </div>
    );
}

export default ChartsRestock;
