import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Loading, RichTextEditor } from '../../components';
import { DeleteModal } from '../../components';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import LinksModal from '../HyperLinks/LinksModal';
import useCurrentUser from '../../utils/hooks/useCurrentUser';

function Websites() {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();

    const { checkAccess } = useAccessChecker();

    const [WebsiteModal, setWebsiteModal] = useState(false)
    const [WebsiteId, setWebsiteId] = useState(null)
    const [niches, setNiches] = useState(null)
    const [tag, setTag] = useState(null)


    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState(null)
    const [ModalLinks, setModalLinks] = useState(null)


    const [formData, setFormData] = useState({ name: null, domain: null, hyperLinkPrice: null, blogPrice: null, slug: null, meta_description: null, meta_title: null })
    const [formDataLinks, setFormDataLinks] = useState({ website: null, category: null, url: null, anchor: null, type: null, pricePlan: null, schedule_at: null })

    const [loading, setLoding] = useState(false)
    const [Website, setWebsite] = useState([])
    const [done, setDone] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)



    const HandleOnChange = (e) => {
        setFormDataLinks({ ...formDataLinks, [e.target.name]: e.target.value });
    };


    const [SoldModal, setSoldModal] = useState()


    const checkSold = (type, item) => {
        if (type === 'Blogs') {
            if (currentUser.blogs >= 1) {
                navigate(`/blogs/New/${item.id}/${item.name}`)
            }
            else if (currentUser.balance >= item.blogPrice) {
                navigate(`/blogs/New/${item.id}/${item.name}`)

            } else {
                setSoldModal(true)
            }
        } else if (type === 'HyperLinks') {
            if (currentUser.hyperLinks >= 1) {
                setModalLinks(item); HandleOnChange({ target: { name: 'website', value: item.id } })
            }
            else if (currentUser.balance >= item.hyperLinkPrice) {
                setModalLinks(item); HandleOnChange({ target: { name: 'website', value: item.id } })
            } else {
                setSoldModal(true)
            }
        }
    }





    useEffect(() => {
        getWebsite()

    }, [WebsiteId, done])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getWebsite = async (page = 1) => {
        try {
            setLoding(true);
            const response = await axiosInstance.get(`/websites/search?page=${page}&per_page=10`);
            const responseData = response.data;

            if (responseData) {
                setWebsite(responseData.data);
                setCurrentPage(responseData.current_page);
                setTotalPages(responseData.last_page);
            }
            setLoding(false);
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            getWebsite(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            getWebsite(currentPage - 1);
        }
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        const newValue = type === "number" ? parseFloat(value, 10) : value;

        setFormData({ ...formData, [name]: newValue });
    };


    const InitialiseWebsite = () => {
        setFormData({ name: null, domain: null, slug: null, hyperLinkPrice: null, blogPrice: null, meta_description: null, meta_title: null })
    }

    const initializeLinks = () => {
        setFormDataLinks({ website: null, category: null, url: null, anchor: null, type: null, pricePlan: null, schedule_at: null })
    }

    const handleToggleModal = (id) => {
        if (id) {
            setWebsiteId(id)
            getWebsiteToUpdate(id)

        } else {
            setWebsiteModal(!WebsiteModal)
            InitialiseWebsite()

        }
    }

    const getWebsiteToUpdate = async (id) => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/websites/get/${id}`);
            const responseData = response.data
            if (responseData) {
                setFormData(responseData.data)
                setWebsiteModal(!WebsiteModal)

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    }
    const find = async (e) => {
        try {
            e.preventDefault()
            setLoding(true)
            const response = await axiosInstance.post(`/websites/find`, { searchValue });
            const responseData = response.data

            if (responseData) {
                setWebsite(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            if (WebsiteId) {
                response = await axiosInstance.put(`/websites/update/${WebsiteId}`, formData);
            } else {
                response = await axiosInstance.post('/websites/create', formData);
            }
            const responseData = response.data
            if (responseData) {
                if (WebsiteId) {
                    toast.success(responseData.message);
                    setWebsiteId(null)
                    setWebsiteModal(false)
                    InitialiseWebsite()
                    setDone(!done)
                } else {
                    toast.success(responseData.message);
                    handleToggleModal()
                    setDone(!done)
                }
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
        }
    };


    const reset = () => {
        setSearchValue('')
        getWebsite()
        InitialiseWebsite()
    }


    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }


    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }} // Start slightly scaled up for a more dynamic effect
            animate={{ opacity: 1, scale: 1 }} // Full opacity and normal scale
            exit={{ opacity: 0, scale: 0.95 }} // Scale down slightly when exiting
            transition={{
                duration: 0.5, // Shorter duration for quicker transitions
                ease: [0.4, 0, 0.2, 1], // Custom ease-in-out curve for smoother acceleration and deceleration
            }}
            className="flex flex-col w-full justify-start md:p-6 p-2 h-[100vh]">
            {loading &&
                <Loading />
            }
            {/* component */}
            <section className=" w-full mx-auto">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex  items-center gap-x-3">
                            <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Websites</h2>
                            <span className="px-3 py-1 text-xs dark:bg-secondary/10 dark:text-secondary rounded-full text-primary bg-primary/10">{Website.length} Website</span>
                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">These companies have purchased in the last 12 months.</p>
                    </div>
                    <div className="flex items-center mt-4 gap-x-3">

                        {checkAccess('websites', 'create') &&

                            <button onClick={() => { handleToggleModal() }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>Add Website</span>
                            </button>
                        }
                    </div>
                </div>
                <div className="mt-6 md:flex md:items-center w-full md:justify-between">

                    <form className="flex items-center md:w-1/3 w-full  ">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute cursor-pointer z-50 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg onClick={(e) => reset(e)} className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2" />
                                </svg>
                            </div>
                            <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} id="simple-search" className="peer h-11 block w-full border bg-neutral-50  border-neutral-200 rounded-lg text-sm  pl-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600" placeholder="Search branch name..." required />
                        </div>
                        <button type="submit" onClick={(e) => find(e)} disabled={!searchValue} className="h-[2.6rem] w-[2.6rem] px-3 flex justify-center items-center ms-2 text-sm font-medium text-white  rounded-lg text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </form>
                </div>
                <div className="flex flex-col mt-6">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden border border-neutral-200 dark:border-neutral-700 md:rounded-lg">
                                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                                    <thead className="bg-neutral-50 dark:bg-neutral-800">
                                        <tr>

                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Name
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Domain
                                            </th>

                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Date
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Blog
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                HyperLink
                                            </th>
                                            {
                                                (checkAccess('websites', 'update') || checkAccess('websites', 'delete')) &&

                                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">Actions</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200 text-start dark:divide-neutral-700 dark:bg-neutral-900">
                                        {
                                            Website.length > 0 &&
                                            Website.map(item => {
                                                return (
                                                    <tr className='h-[4rem]'>
                                                        <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                            <div>
                                                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.name}</p>
                                                            </div>
                                                        </td>
                                                        <td className=" px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                            <a href={item.domain} target='_blank' className="inline px-3 py-1 text-sm font-normal text-blue-500 underline">
                                                                {item.domain}
                                                            </a>
                                                        </td>


                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{formatDate(item.created_at)}</p>
                                                            </div>
                                                        </td>
                                                        <td className=" px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                            <a onClick={() => checkSold('Blogs', item)} className="inline cursor-pointer px-5 py-1 text-sm font-normal text-white bg-orange-600 rounded-lg">
                                                                {item.blogPrice}$
                                                            </a>
                                                        </td>
                                                        <td className=" px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                            <a onClick={() => { checkSold('HyperLinks', item) }} className="inline cursor-pointer px-5 py-1 text-sm font-normal text-white bg-orange-600 rounded-lg">
                                                                {item.hyperLinkPrice}$
                                                            </a>
                                                        </td>
                                                        {
                                                            (checkAccess('websites', 'update') || checkAccess('websites', 'delete')) &&

                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className='flex justify-start items-center gap-2'>
                                                                    {checkAccess('websites', 'delete') &&

                                                                        <a onClick={() => { setWebsiteId(item.id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                                                        </a>
                                                                    }
                                                                    {checkAccess('websites', 'update') &&

                                                                        <a onClick={() => handleToggleModal(item.id)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                                        </a>
                                                                    }

                                                                </div>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
                    <div className="text-sm text-neutral-500 dark:text-neutral-400">
                        Page <span className="font-medium text-neutral-700 dark:text-neutral-100">{currentPage} of {totalPages}</span>
                    </div>
                    <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                        <button
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>
                            <span>Previous</span>
                        </button>
                        <button
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <span>Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>

            </section>



            <AnimatePresence>

                {WebsiteModal &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="w-full md:w-[40%] py-4 relative mx-auto mx-2 h-fit overflow-y-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                            <div className="flex  gap-3 flex-col justify-center items-center w-full px-6 py-3">
                                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                    <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Add Website")}</h2>
                                    <a onClick={() => handleToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-600/10 text-green-600'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                                </div>
                                <div className='grid md:grid-cols-2 w-full place-items-center gap-3 '>

                                    <Input type='text' disabled={false} label={t("Name")} name="name" handleChange={handleChange} defaultV={formData.name || null} />


                                    <Input type='text' disabled={false} label={t("Domain")} name="domain" handleChange={handleChange} defaultV={formData.domain || null} />

                                    <Input type='number' disabled={false} label={t("Blog Price")} name="blogPrice" handleChange={handleChange} defaultV={formData.blogPrice || null} />

                                    <Input type='number' disabled={false} label={t("HyperLink Price")} name="hyperLinkPrice" handleChange={handleChange} defaultV={formData.hyperLinkPrice || null} />




                                    <Input type='text' disabled={false} label={t("Meta title")} name="meta_title" handleChange={handleChange} defaultV={formData.meta_title || null} />


                                    <Input type='text' disabled={false} label={t("Meta description")} name="meta_description" handleChange={handleChange} defaultV={formData.meta_description || null} />



                                </div>
                                <Input type='text' disabled={false} label={t("Slug")} name="slug" handleChange={handleChange} defaultV={formData.slug || null} />




                                <div className='md:col-span-3 mt-10 w-full flex items-center justify-end '>
                                    <div className="flex items-center mt-6 gap-x-3">
                                        <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            {
                                                !loading ?
                                                    (
                                                        <>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            <span>{t("Save website")}</span>
                                                        </>
                                                    )
                                                    :

                                                    (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>)

                                            }

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence >

            <AnimatePresence>

                {SoldModal &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white dark:bg-neutral-800 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 shrink-0 items-center text-orange-600 justify-center rounded-full bg-orange-600/10 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg
                                            className="h-6 w-6 text-orange-600"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                            data-slot="icon"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3
                                            className="text-base capitalize font-semibold text-neutral-800 dark:text-neutral-200"
                                            id="modal-title"
                                        >
                                            Insufficient Funds
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-600 dark:text-neutral-400">
                                                You do not have enough funds to complete this purchase. Please recharge your account to proceed with your transactions. Click the button below to visit your balance page and add funds.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 dark:bg-neutral-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <Link
                                    to="/balance"
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                                >
                                    Recharge Account
                                </Link>
                                <button
                                    onClick={() => setSoldModal(false)}
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-neutral-800 px-3 py-2 text-sm font-semibold text-neutral-800 dark:text-neutral-200 shadow-sm border border-neutral-200 dark:border-neutral-700 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>


                        </motion.div>
                    </div>
                }
            </AnimatePresence >

            <DeleteModal setDeleteId={setWebsiteId} open={deleteModal} setOpen={setDeleteModal} DeleteId={WebsiteId} Model='websites' setDone={setDone} setLoding={setLoding} done={done} />

            <LinksModal Loading={loading} formDataLinks={formDataLinks} initializeLinks={initializeLinks} HandleOnChange={HandleOnChange} setModalLinks={setModalLinks} ModalLinks={ModalLinks} setDone={setDone} setLoding={setLoding} done={done} id={null} />

        </motion.div >


    )
}

export default Websites