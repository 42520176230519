import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Input, SelectVariant, Loading, Textarea, RichTextEditor } from '../../components'; // Import additional components
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';

function FormBlogs() {
    const [done, setDone] = useState(false);

    const navigate = useNavigate();
    const languages = [
        { name: 'Arabic', id: 'Arabic' },
        { name: 'English', id: 'English' },
    ];

    const { website_id, name } = useParams()

    const [Website, setWebsite] = useState([])
    const [Categories, setCategories] = useState([])

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        title: null,
        meta_title: null,
        meta_description: null,
        content: null,
        image: null,
        website: website_id,
        category: null,
        description: null,
        pricePlan: null,
        slug: null,
        expire_at: null,
        schedule_at: null,


    });

    const { id } = useParams();

    useEffect(() => {
        getWebsite()
        getCategories()

        if (id) {
            getBlogToUpdate();
        } else {
            initializeBlog();
        }
    }, [done]);

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            const file = e.target.files[0];
            if (file) {
                if (file.size > 15 * 1024 * 1024) {
                    toast.warning('File size exceeds 15 MB limit');
                    e.target.value = '';
                    return;
                }
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setFormData({ ...formData, image: reader.result });
                };
            }
        } else {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            setFormData({ ...formData, [e.target.name]: value });
        }
    };

    const handleContentChange = (content) => {
        setFormData({ ...formData, content });
    };

    const initializeBlog = () => {

        return setFormData({
            title: null,
            meta_title: null,
            meta_description: null,
            content: null,
            image: null,
            website: website_id,
            category: null,
            description: null,
            pricePlan: null,
            slug: null,
            expire_at: null,
            schedule_at: null,

        });
    };

    const getWebsite = async () => {
        try {
            const response = await axiosInstance.get('/websites/search');
            const responseData = response.data

            if (responseData) {
                setWebsite(responseData.data)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    };

    const getCategories = async () => {
        try {
            const response = await axiosInstance.post('/categories/search', { website_id });
            const responseData = response.data

            if (responseData) {
                setCategories(responseData.data)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    };
    const getBlogToUpdate = async () => {
        try {
            setErrorMessage('');
            setLoading(true);
            const response = await axiosInstance.get(`/blogs/get/${id}`);
            setFormData(response.data.data);
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setLoading(false);
            console.error('Failed to fetch blog:', error.response.data);
        }
    };

    const save = async (e) => {
        e.preventDefault();
        try {
            setErrorMessage('');
            setLoading(true);
            let response = null;
            if (id) {
                response = await axiosInstance.put(`/blogs/update/${id}`, formData);
            } else {
                response = await axiosInstance.post('/blogs/create', formData);
            }
            const responseData = response.data;
            if (responseData && responseData.success) {
                toast.success(responseData.message);
                if (!id) {
                    initializeBlog();
                    navigate('/Websites')
                }
            } else {
                toast.warning(responseData.message);
                navigate('/Websites')

            }
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.response.data.message);
            toast.error(error.response.data.message);
            setLoading(false);
            console.error('Blog save failed:', error.response.data);
        }
    };





    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            }} className='relative w-full h-full'>
            {loading && <Loading />}
            <div className='grid my-14 md:px-44 px-4'>
                <div className="sm:flex sm:items-center mb-6 sm:justify-between">
                    <div>
                        <ol className="flex items-center whitespace-nowrap mb-5">
                            <li className="inline-flex items-center">
                                <Link to='/blogs' className="flex items-center text-sm text-neutral-500 hover:text-green-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 me-3 size-4 lucide-lock-keyhole">
                                        <circle cx="12" cy="16" r="1" />
                                        <rect x="3" y="10" width="18" height="12" rx="2" />
                                        <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                                    </svg>
                                    Blogs
                                </Link>
                                <svg className="flex-shrink-0 mx-2 overflow-visible size-4 text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="m9 18 6-6-6-6"></path>
                                </svg>
                            </li>
                            <li className="inline-flex items-center text-sm font-semibold text-neutral-800 dark:text-neutral-100 truncate" aria-current="page">
                                {id ? 'Edit Blog' : 'New Blog'} : {name}
                            </li>
                        </ol>
                        <div className="flex items-center gap-x-3">
                            <h2 className="text-xl font-semibold dark:text-neutral-100 text-neutral-800">Blog Form</h2>
                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500">Here you can add or edit a blog post.</p>
                    </div>
                </div>
                <div className='w-full flex md:flex-row gap-5 flex-col '>

                    <div className=' w-full bg-white dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-2xl px-6 py-5'>
                        <form onSubmit={save} method="POST">

                            {!formData.image ?
                                <div className="flex items-center  justify-center mb-5 border-2 w-full h-[25rem] mx-auto  rounded-xl p-2 border-neutral-200 border-dashed dark:border-neutral-700">
                                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full rounded-xl cursor-pointer bg-neutral-50 dark:bg-neutral-900 hover:bg-neutral-100">

                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-camera w-8 h-8 mb-4 text-neutral-500 dark:text-neutral-400"><path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z" /><circle cx={12} cy={13} r={3} /></svg>
                                            <p className="mb-2 text-xs text-neutral-500 dark:text-neutral-400 text-semibold">Upload photo</p>


                                            <div className='text-center text-xs text-neutral-500 pb-4 dark:text-neutral-400 text-[500] '>
                                                Allowed *.jpeg, *.jpg, *.png, *.gif <br />
                                                <span>max size of 3 Mb</span>
                                            </div>
                                        </div>


                                        <input onChange={(e) => handleChange(e)} id="dropzone-file" type="file" name="image" className="hidden" />
                                    </label>

                                </div>
                                :
                                <>
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6 w-full">
                                        <img src={formData.image} alt="Uploaded" className='rounded-xl m-1 object-cover h-[25rem] w-full' />
                                    </div>
                                    <a onClick={(e) => setFormData({ ...formData, image: null })} className='flex cursor-pointer mb-5 justify-start items-center gap-2 px-2  rounded-md border text-[12px] border-neutral-200 bg-neutral-50 w-fit mx-auto dark:bg-neutral-900 dark:border-neutral-700 text-neutral-800 dark:text-neutral-100'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                        Image

                                    </a>
                                </>
                            }



                            <div className="grid gap-4 mb-6">
                                <SelectVariant label="Category" options={Categories} name="category" disabled={id ? true : false} handleChange={handleChange} defaultV={formData.category?.id || null} />

                                <div className="grid md:grid-cols-2 gap-4 ">

                                    <Input type='text' disabled={false} label="Title" name="title" handleChange={handleChange} defaultV={formData.title || null} />
                                    <Input type='text' disabled={false} label="Slug" name="slug" handleChange={handleChange} defaultV={formData.slug || null} />

                                </div>
                                <textarea
                                    placeholder='Description...'
                                    rows={4}
                                    name="description"
                                    onChange={handleChange}
                                    defaultValue={formData.description || ''}
                                    className='peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600'
                                ></textarea>
                                <Input type='text' disabled={false} label="Meta Title" name="meta_title" handleChange={handleChange} defaultV={formData.meta_title || null} />
                                <Input type='text' disabled={false} label="Meta Description" name="meta_description" handleChange={handleChange} defaultV={formData.meta_description || null} />
                                <RichTextEditor
                                    value={formData.content}
                                    onChange={handleContentChange}
                                />

                                <div className='flex flex-col justify-center items-start gap-2'>
                                    <label className='font-[700] text-[16px]'>Schedule</label>
                                    <input
                                        type="datetime-local"
                                        className="peer p-4 block w-full border bg-neutral-50 border-neutral-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600"
                                        name="schedule_at"
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={formData.schedule_at ? formData.schedule_at.substring(0, 16) : null} // Format to 'YYYY-MM-DDTHH:MM'
                                    />
                                </div>



                            </div>
                            <div className='flex justify-end items-center gap-4 mt-24'>
                                <button type="submit" className='px-4 py-2 text-white bg-green-600 dark:bg-green-600 rounded-md'>{
                                    !loading ?
                                        (
                                            <>

                                                <span>Save</span>
                                            </>
                                        )
                                        :

                                        (<div className="animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                            <span className="sr-only">Loading...</span>
                                        </div>)

                                }</button>
                                <Link to='/Websites' className='px-4 py-2 text-white bg-neutral-600 rounded-md'>Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default FormBlogs;
